import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './components/ui/Card';
import { FileTextIcon, LocateIcon, UsersIcon, FileIcon } from './components/icons';
//import Modal from 'react-modal';
import TextToSpeech from './components/TextToSpeech';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity as getPatient } from 'app/entities/patient/patient.reducer';

import { getConsultationEntities as getQuotes } from 'app/entities/patient/consultation/quotes/quote.reducer';

import { IImage } from 'app/shared/model/image.model';
import ImageViewer from 'app/entities/patient/consultation/image/image-viewer';
import ConsultationList from 'app/patient-app/components/ConsultationList';

import { Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { IReport } from 'app/shared/model/report.model';
import { fetchAllConsultationData } from 'app/entities/patient/consultation/consultation-extra.reducer';

import ImageCollectionRenderer from './components/ImageCollectionRenderer';
import { set } from 'lodash';
import PatientModalWithLegend from './components/PatientModalWithLegend';

const PatientApp = () => {
  // en dur pour l'heure db9646ad-9fed-484c-b851-8905d8b77a4a
  const [patientInfo, setPatientInfo] = useState(null);
  const [error, setError] = useState(null);

  const [xRays, setXRays] = useState([]);
  const [panoramics, setPanoramics] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [lastImages, setLastImages] = useState([]);
  const [onlyPanoramic, setOnlyPanoramic] = useState(false);
  const patient = useAppSelector(state => state.patient.entity);
  const modalRef = useRef(null);
  const consultations = useAppSelector(state => state.consultation.entities);
  const quotes = useAppSelector(state => state.quote.entities);
  const consultation = useAppSelector(state => state.consultation.entity);
  const account = useAppSelector(state => state.authentication.account);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const [radioAffectationEnded, setRadioAffectationEnded] = useState(false);
  const [detailedImage, setDetailedImage] = useState(null);
  const [xraysSize, setXraysSize] = useState(0);

  const imageContainers = useRef(new Map());

  const [report, setReport] = useState<IReport>(null);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const patientId = useAppSelector(state => state.authentication.account.loggedPatientInfo?.id);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (account && patientId) {
      dispatch(fetchAllConsultationData({ patientId, locale: 'FR' }));
    }
  }, [patientId]);

  useEffect(() => {
    if (consultation) {
      const images: [IImage] = consultation.images;
      if (images && images.length > 0) {
        // Group images by consultation.id
        const imagesByConsultation = images.reduce((acc, image) => {
          const consultationId = image.consultation?.id; // Use consultation.id if it exists
          if (consultationId) {
            if (!acc[consultationId]) {
              acc[consultationId] = [];
            }
            acc[consultationId].push(image);
          }
          return acc;
        }, {});
  
        // For example, if you want the images from the latest consultation:
        const latestConsultationId = consultations[consultations.length - 1].id;
        const latestConsultationImages = imagesByConsultation[latestConsultationId] || [];
  
        setRadioAffectationEnded(false);
        const newXRays = [];
        const newPhotos = [];
        let newPanoramics = [];
        let xrayCount = 0;
  
        latestConsultationImages.forEach(image => {
          if (image.pathologyPredictions && image.pathologyPredictions.length > 0) {
            if (image.type === 'panoramic') {
              newPanoramics.push(image);
              imageContainers.current.set(image.id, createRef());
            } else if (image.type === 'xray') {
              xrayCount += 1;
              newXRays.push(image);
              imageContainers.current.set(image.id, createRef());
            } else if (image.type === 'photo') {
              newPhotos.push(image);
              imageContainers.current.set(image.id, createRef());
            }
          }
        });
  
        let lastImages = [];
        const lastImagesMax = 8;
        if (newXRays.length > 0) {
          lastImages = [...newXRays.slice(0, Math.max(lastImagesMax, newXRays.length))];
          if (lastImages.length < lastImagesMax) {
            lastImages = [...lastImages, ...newPhotos.slice(0, Math.max(lastImagesMax - lastImages.length, newPhotos.length))];
          }
        } else if (newPhotos.length > 0) {
          lastImages = [...newPhotos.slice(0, Math.max(lastImagesMax, newPhotos.length))];
        } else if (newPanoramics.length > 0) {
          lastImages.push(newPanoramics[newPanoramics.length - 1]);
          setOnlyPanoramic(true);
        }
  
        setLastImages(lastImages);
        setXraysSize(xrayCount);
        setXRays(newXRays);
        setPhotos(newPhotos);
        setPanoramics(newPanoramics);
        setRadioAffectationEnded(true);
      }
      if (consultation.report) {
        setReport(consultation.report);
      }
      if (consultations && consultations.length > 0) {
        dispatch(getQuotes({ patientId, consultationId: consultations[consultations.length - 1].id }));
      }
    }
  }, [consultation]);
  

  if (!isAuthenticated) {
    return <div>Veuillez vous créer votre compte</div>;
  }

  const handleViewQuote = (consultationId, quote) => {
    window.open(`api/patients/${patientId}/consultations/${consultationId}/quotes/${quote.id}/file`, '_blank');
  };

  const generateMail = useCallback(
    consultation => {
      if (!consultation.report || consultation?.report?.recommendations.length === 0) {
        return '';
      }

      const recommendations = consultation?.report?.recommendations.map(rec => rec.replaceAll('\\n', '\n')).join('\n\n');

      const generatedMail = `${translate('dfaiApp.consultation.report.mailPrefix')}${recommendations}${translate('dfaiApp.consultation.report.mailSuffix')}`;

      return generatedMail;
    },
    [translate],
  );

  return (
    <div className="container mx-auto">
      <section className="w-full py-12 md:py-24 lg:py-32 flex flex-col items-center">
        <div className="container space-y-12  md:px-6 mx-auto">
          <div className="flex flex-col items-center justify-center space-y-4 text-center">
            <div className="space-y-2">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Bonjour, {account.loggedPatientInfo?.firstName}</h2>
              <p className="max-w-[900px] text-muted-foreground md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Toutes vos données dentaires dans une seule et unique application.
              </p>
            </div>
          </div>

          {/* Colonne avec les images, vidéos et courrier */}
          <div className="flex flex-col space-y-4">
            <Card>
              <CardHeader>
                <CardTitle>Dernières Images</CardTitle>
              </CardHeader>
              <CardContent>
                {/* Colonne pour Images */}
                <div className="flex flex-col space-y-4">
                  {/* Colonne pour Prédiction */}
                  {(panoramics && panoramics.length > 0) || (xRays && xRays.length > 0) || (photos && photos.length > 0) ? (
                    <div>
                      {lastImages && lastImages.length > 0 && (
                        <ImageCollectionRenderer
                          title=""
                          images={lastImages}
                          height="15vh"
                          minHeight="15vh"
                          singleLine={onlyPanoramic}
                          setDetailedImage={setDetailedImage}
                          imageContainers={imageContainers}
                          patientId={patientId}
                          simpleImages={true}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
                {detailedImage && (
                  <PatientModalWithLegend
                    selectedImageEntity={detailedImage}
                    closePredictionModal={() => setDetailedImage(null)}
                    patientId={patientId}
                    simpleImages={true}
                    legends={false}
                  />
                )}
              </CardContent>
              <CardFooter>
                {consultations.length > 0 && (
                  <Link
                    to={`/patient-app/appointments/${consultations[consultations.length - 1].id}`}
                    className="text-sm font-medium text-primary-tailwind"
                  >
                    Voir toutes les images
                  </Link>
                )}
              </CardFooter>
            </Card>

            <Card>
              <CardHeader>
                <CardTitle>Courrier de votre praticien</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-muted-foreground">{generateMail(consultation)}</p>
                <TextToSpeech text={generateMail(consultation)} /> {/* Ajout du composant ici */}
              </CardContent>
            </Card>
          </div>
          <div className="grid gap-8 md:grid-cols-2">
            <Card>
              <CardHeader>
                <CardTitle>Dernier Rapport</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  {consultation.report && (
                    <div className="flex items-center gap-2">
                      <FileTextIcon className="h-8 w-8 text-muted-foreground" />
                      <div className="grid gap-1">
                        <div className="font-medium">Rapport de consultation</div>
                      </div>
                      <Link to={`./report/${consultation.report.consultationId}`} className="ml-auto">
                        <FileIcon className="h-8 w-8 text-muted-foreground" />
                      </Link>
                    </div>
                  )}
                </div>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Dernier Devis</CardTitle>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  {Array.isArray(quotes) &&
                    quotes.slice(0, 4).map((quote, index) => (
                      <div key={index} className="flex items-center gap-2">
                        <FileTextIcon className="h-8 w-8 text-muted-foreground" />
                        <div className="grid gap-1">
                          <div className="font-medium"> {quote.originalName ? quote.originalName : quote.sourceFileName}</div>
                        </div>
                        <div className="ml-auto cursor-pointer">
                          <FileIcon
                            onClick={() => handleViewQuote(consultation.report?.consultationId, quote)}
                            className="h-8 w-8 text-muted-foreground"
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
      <ConsultationList />
    </div>
  );
};

export default PatientApp;
