import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getEntities as getConsultations,
  getConsultationsOverview,
  getOverviewEntityReport,
} from 'app/entities/patient/consultation/consultation.reducer';

export const fetchAllConsultationData = createAsyncThunk(
  'consultation/fetchAllConsultationData',
  async ({ patientId, locale }: { patientId: string; locale: string }, { dispatch }) => {
    await dispatch(getConsultations({ params: {}, patientId }));
    await dispatch(getConsultationsOverview(patientId));
    await dispatch(getOverviewEntityReport({ patientId, locale }));
  }
);