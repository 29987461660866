import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity, getClinicPhoto } from './clinic.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import Professional from '../professional/professional';
import PredictionFilter from '../prediction-filter/prediction-filter';

export const ClinicDetail = () => {
  const dispatch = useAppDispatch();

  const { clinicId } = useParams<'clinicId'>();
  const authorities = useAppSelector(state => state.authentication.account?.authorities);
  const clinicEntity = useAppSelector(state => state.clinic.entity);
  const [photoUrl, setPhotoUrl] = useState('');

  useEffect(() => {
    dispatch(getEntity(clinicId));
  }, []);

  useEffect(() => {
    const fetchPhoto = async () => {
      if (clinicEntity && clinicEntity.photoFileName) {
        try {
          const response = await dispatch(getClinicPhoto(clinicEntity.id)).unwrap();
          const objectURL = URL.createObjectURL(response);
          setPhotoUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load photo:', error);
        }
      }
    };

    fetchPhoto();
  }, [clinicEntity, dispatch]);

  return (
    <Row>
      <Col md="8">
        <Row>
          <div className="d-flex justify-content-between align-items-center">
            <h2 data-cy="clinicDetailsHeading">
              <Translate contentKey="dfaiApp.clinic.detail.title">Clinic</Translate>
            </h2>
            {photoUrl && 
              <span>
                <img src={photoUrl} alt="patient" className="img-fluid" style={{ width: 'auto', height: '50px' }}/>
              </span>}
            <div className="inline-flex justify-content-end">
              <Button tag={Link} to="/clinic" replace color="info" data-cy="entityDetailsBackButton" size="lg">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              { hasAnyAuthority(authorities, [AUTHORITIES.UPDATE_CLINIC]) &&
                <Button tag={Link} to={`/clinic/${clinicEntity.id}/edit`} replace color="info" size="lg">
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </Button>
              }
            </div>
          </div>
        </Row>
        <Row className="justify-content-center">
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="dfaiApp.clinic.id">Id</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.id}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="dfaiApp.clinic.name">Name</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.name}</dd>
            <dt>
              <span id="address">
                <Translate contentKey="dfaiApp.clinic.address">Address</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.address}</dd>
            <dt>
              <span id="postalCode">
                <Translate contentKey="dfaiApp.clinic.postalCode">postalCode</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.postalCode}</dd>
            <dt>
              <span id="city">
                <Translate contentKey="dfaiApp.clinic.city">city</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.city}</dd>
            <dt>
              <span id="country">
                <Translate contentKey="dfaiApp.clinic.country">country</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.country}</dd>
            <dt>
              <span id="phone">
                <Translate contentKey="dfaiApp.clinic.phone">Phone</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.phone}</dd>
            <dt>
              <span id="email">
                <Translate contentKey="dfaiApp.clinic.email">Email</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.email}</dd>
            <dt>
              <span id="siret">
                <Translate contentKey="dfaiApp.clinic.siret">Siret</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.siret}</dd>
            <dt>
              <span id="isActive">
                <Translate contentKey="dfaiApp.clinic.isActive">Is Active</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.isActive ? 'true' : 'false'}</dd>
            <dt>
              <span id="licences">
                <Translate contentKey="dfaiApp.clinic.licences">Licence count</Translate>
              </span>
            </dt>
            <dd>{clinicEntity.licences}</dd>
            </dl>
        </Row>
        <Row>
          <Professional />
        </Row>
        <Row>
          { hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]) && <PredictionFilter />}
        </Row>
      </Col>
    </Row>
  );
};

export default ClinicDetail;
