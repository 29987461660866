import React from 'react';
import { Navigate, Route, useLocation, redirect } from 'react-router-dom';
import Loadable from 'react-loadable';

import LoginRedirect from 'app/modules/login/login-redirect';
import Logout from 'app/modules/login/logout';
import EntitiesRoutes from 'app/entities/routes';
import PatientUserRoutes from 'app/patient-app/routes';
import PrivateRoute, { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import { useAppSelector } from './config/store';

const loading = <div>loading ...</div>;

const Admin = Loadable({
  loader: () => import(/* webpackChunkName: "administration" */ 'app/modules/administration'),
  loading: () => loading,
});

const RedirectToAppropriatePage = () => {
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const authorities = useAppSelector(state => state.authentication.account?.authorities);
  const location = useLocation();

  if (!isAuthenticated) {
    return <LoginRedirect />;
  }

  if (hasAnyAuthority(authorities, [AUTHORITIES.PATIENT_USER])) {
    return <Navigate to="/patient-app" replace />;
  }

  if (hasAnyAuthority(authorities, [AUTHORITIES.ADMIN])) {
    return <Navigate to="/clinic" replace />;
  }

  return <Navigate to="/patient" replace />;
};

const AppRoutes = () => {
  return (
    <div className="view-routes">
      <ErrorBoundaryRoutes>
        <Route path="oauth2/authorization/oidc" element={<LoginRedirect />} />
        <Route
          index
          element={
            <PrivateRoute hasAnyAuthorities={[]}>
              <RedirectToAppropriatePage />
            </PrivateRoute>
          }
        />
        <Route path="logout" element={<Logout />} />
        <Route
          path="admin/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
              <Admin />
            </PrivateRoute>
          }
        />
        <Route
          path="patient-app/*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.PATIENT_USER]}>
              <PatientUserRoutes />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute hasAnyAuthorities={[AUTHORITIES.USER]}>
              <EntitiesRoutes />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<PageNotFound />} />
      </ErrorBoundaryRoutes>
    </div>
  );
};

export default AppRoutes;
