import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Clinic from './clinic';
import ClinicDetail from './clinic-detail';
import ClinicUpdate from './clinic-update';
import ClinicDeleteDialog from './clinic-delete-dialog';
import PrivateRoute from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ProfessionalUpdate from '../professional/professional-update';
import PredictionFilter from '../prediction-filter/index';
import ProfessionalDetail from '../professional/professional-detail';
<<<<<<< HEAD
=======

>>>>>>> 3edf13df0d470fadd0917830463ddf9a48e0688a

const ClinicRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={
      <PrivateRoute hasAnyAuthorities={[AUTHORITIES.VIEW_CLINIC]}>
        <Clinic />
      </PrivateRoute>
    } />
    <Route path="new" element={
      <PrivateRoute hasAnyAuthorities={[AUTHORITIES.CREATE_CLINIC]}>
        <ClinicUpdate />
      </PrivateRoute>
    } />
    <Route path=":clinicId">
      <Route index element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.VIEW_CLINIC]}>
          <ClinicDetail />
        </PrivateRoute>
      } />
      <Route path="edit" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.UPDATE_CLINIC]}>
          <ClinicUpdate />
        </PrivateRoute>
      } />
      <Route path="delete" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.DELETE_CLINIC]}>
          <ClinicDeleteDialog />
        </PrivateRoute>
      } />
      <Route path="professional/new" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.MANAGE_CLINIC]}>
          <ProfessionalUpdate />
        </PrivateRoute>
      } />
      <Route path="professional/:id">
          <Route index element={<ProfessionalDetail />} />
      </Route>
      <Route path="professional/:id/edit">
          <Route index element={<ProfessionalUpdate />} />
      </Route>
      
      <Route path="prediction-filter/*" element={
        <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
          <PredictionFilter />
        </PrivateRoute>
      } />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ClinicRoutes;
