import React from 'react';
import { Link } from 'react-router-dom';

const PatientFooter = () => {
  return (
    <footer className="bg-gray-800 text-white py-6 w-full">
      <div className="mx-auto px-4-tailwind">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <Link to="/" className="flex items-center justify-center footer-patient-tailwind">
              <span className="mt-2">© 2024 Dragonfly AI Medical. All rights reserved.</span>
            </Link>
          </div>
          <div className="flex space-x-6">
            <Link to="patient-app/legal" className="hover:underline footer-patient-tailwind">
              Informations Légales
            </Link>
            <Link to="patient-app/privacy-policy" className="hover:underline footer-patient-tailwind">
              Politique de Confidentialité
            </Link>
            <Link to="patient-app/terms-of-service" className="hover:underline footer-patient-tailwind">
              Conditions d'Utilisation
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default PatientFooter;
