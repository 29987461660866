import React from 'react';

const LegalInformation = () => {
  return (
      <div className="container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Informations Légales</h1>
        <p className="mb-4">
          Conformément aux dispositions des Articles 6-III et 19 de la Loi n°2004-575 du 21 juin 2004 pour la Confiance dans l'économie numérique, dite L.C.E.N., nous portons à la connaissance des utilisateurs et visiteurs du site : dragonfly-ai-medical.com les informations suivantes :
        </p>
        <h2 className="text-2xl font-semibold mb-4">Éditeur</h2>
        <p className="mb-4">
          Le site dragonfly-ai-medical.com est la propriété exclusive de SARL Dragonfly AI Medical, qui l'édite.
        </p>
        <p className="mb-4">
          SARL Dragonfly AI Medical<br />
          Siège social : 123 Rue de la Santé, 75000 Paris, France<br />
          Téléphone : 01 23 45 67 89<br />
          Email : contact@dragonfly-ai-medical.com<br />
          Directeur de la publication : Jean Dupont<br />
        </p>
        <h2 className="text-2xl font-semibold mb-4">Hébergement</h2>
        <p className="mb-4">
          Le site est hébergé par :<br />
          Hébergeur : OVH<br />
          Siège social : 2 Rue Kellermann, 59100 Roubaix, France<br />
          Téléphone : 09 72 10 10 07<br />
        </p>
        <h2 className="text-2xl font-semibold mb-4">Développement</h2>
        <p className="mb-4">
          Développement et maintenance : SARL Dragonfly AI Medical<br />
          Adresse : 123 Rue de la Santé, 75000 Paris, France<br />
          Téléphone : 01 23 45 67 89<br />
          Email : dev@dragonfly-ai-medical.com<br />
        </p>
    </div>
    
  );
};

export default LegalInformation;
