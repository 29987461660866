import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import PatientApp from './patient-app';
import Appointments from './pages/Appointments';
import AppointmentDetail from './pages/AppointmentDetail';
import Settings from './pages/Settings';
import LegalInformation from './pages/LegalInformation';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import ReportWrapper from './components/ReportWrapper';
import PatientReport from 'app/entities/patient/consultation/patient-report';

/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}

        <Route path="/appointments" element={<Appointments />} />
        <Route path="/appointments/:id" element={<AppointmentDetail />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/legal" element={<LegalInformation />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/report/:consultationId" element={<PatientReport patientContext />} />
        <Route index element={<PatientApp />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
