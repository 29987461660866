import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { IImage } from 'app/shared/model/image.model';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getConsultationImage } from 'app/entities/patient/consultation/consultation.reducer';
import ImageViewer from 'app/entities/patient/consultation/image/image-viewer';

const legendData = {
  panoramic: [
    { color: '#FF0099', label: 'Carie avancée' },
    { color: '#FF0099', label: 'Carie' },
    { color: '#f72fa7', label: 'Carie intermédiaire' },
    { color: '#fa5cbb', label: 'Carie superficielle' },
    { color: '#e3d005', label: 'Parodontie modérée' },
    { color: '#008a05', label: 'Parodontie normale' },
    { color: '#e31b05', label: 'Parodontie sévère' },
    { color: '#FF7F50', label: 'Tartre' },
    { color: '#800080', label: 'Abcès' },
    { color: '#33CC33', label: 'Amalgame' },
    { color: '#33CC33', label: 'Composite' },
    { color: '#66FFCC', label: 'Implant' },
    { color: '#FFFFCC', label: 'Parodontie' },
    { color: '#87CEEB', label: 'Couronne' },
    { color: '#999966', label: 'ScrewPost' },
    { color: '#999966', label: 'Inlay Core' },
    { color: '#FFF', label: 'Endodontie' },
  ],
  xray: [
    { color: '#FF0099', label: 'Carie avancée' },
    { color: '#FF0099', label: 'Carie' },
    { color: '#f72fa7', label: 'Carie intermédiaire' },
    { color: '#fa5cbb', label: 'Carie superficielle' },
    { color: '#e3d005', label: 'Parodontie modérée' },
    { color: '#008a05', label: 'Parodontie normale' },
    { color: '#e31b05', label: 'Parodontie sévère' },
    { color: '#FF7F50', label: 'Tartre' },
    { color: '#800080', label: 'Abcès' },
    { color: '#33CC33', label: 'Amalgame' },
    { color: '#33CC33', label: 'Composite' },
    { color: '#66FFCC', label: 'Implant' },
    { color: '#FFFFCC', label: 'Parodontie' },
    { color: '#87CEEB', label: 'Couronne' },
    { color: '#999966', label: 'Inlay Core' },
    { color: '#999966', label: 'ScrewPost' },
    { color: '#FFF', label: 'Endodontie' },
  ],
  photo: [
    { color: '#FF0099', label: 'Carie avancée' },
    { color: '#FF0099', label: 'Carie' },
    { color: '#f72fa7', label: 'Carie intermédiaire' },
    { color: '#fa5cbb', label: 'Carie superficielle' },
    { color: '#e3d005', label: 'Parodontie modérée' },
    { color: '#008a05', label: 'Parodontie normale' },
    { color: '#e31b05', label: 'Parodontie sévère' },
    { color: '#87CEEB', label: 'Couronne' },
    { color: '#999966', label: 'Inlay Core' },
    { color: '#FF7F50', label: 'Tartre' },
    { color: '#800080', label: 'Abcès' },
    { color: '#33CC33', label: 'Amalgame' },
    { color: '#33CC33', label: 'Composite' },
    { color: '#66FFCC', label: 'Implant' },
    { color: '#FFFFCC', label: 'Parodontie' },
    { color: '#87CEEB', label: 'Couronne métallique' },
    { color: '#a42f13', label: 'Abfraction' },
    { color: '#892710', label: 'Abrasion' },
    { color: '#ff0601', label: 'Gingivale inadaptée' },
  ],
};

interface PatientModalWithLegendProps {
  closePredictionModal: () => void;
  selectedImageEntity: IImage | null;
  simpleImages: boolean;
  patientId: string;
  legends: boolean;
}

const PatientModalWithLegend: React.FC<PatientModalWithLegendProps> = props => {
  const { closePredictionModal, selectedImageEntity, patientId, simpleImages, legends } = props;
  const [imageUrl, setImageUrl] = useState('');
  const modalRef = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchImage = async () => {
      if (selectedImageEntity?.storedName) {
        try {
          setImageUrl('');
          const response = await dispatch(getConsultationImage({ patientId, image: selectedImageEntity })).unwrap();
          const objectURL = URL.createObjectURL(response);
          setImageUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load image:', error);
        }
      }
    };
    fetchImage();
  }, [dispatch, selectedImageEntity, patientId]);

  const currentLegend = selectedImageEntity ? legendData[selectedImageEntity.type] : [];

  return (
    <Modal
      size="lg"
      isOpen={true}
      toggle={closePredictionModal}
      contentLabel="Prediction Modal"
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      onClick={(e) => e.target === e.currentTarget && closePredictionModal()}
    >
      <ModalHeader>
        <button onClick={closePredictionModal} className="absolute top-1 right-2 text-gray-700 hover:text-black">
          <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
        </button>
      </ModalHeader>
      <ModalBody>
        <div className="flex flex-col">
          <div className="relative">
            {simpleImages ? (
              <img src={imageUrl} alt="Prediction" className="w-full h-auto cursor-pointer" />
            ) : (
              <div ref={modalRef} className="w-full h-full relative overflow-hidden" style={{ paddingBottom: '100%' }}>
                <ImageViewer
                  imageEntity={selectedImageEntity}
                  pathologyPredictions={selectedImageEntity.pathologyPredictions}
                  patientId={patientId}
                  containerRef={modalRef}
                  patientContext={true}
                />
              </div>
            )}
          </div>

          {legends && currentLegend.length > 0 && (
            <div className="mt-4">
              <h3 className="text-md font-bold">Légende</h3>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {currentLegend.map((item, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <span className="w-4 h-4 rounded-full" style={{ backgroundColor: item.color }}></span>
                    <span>{item.label}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PatientModalWithLegend;
