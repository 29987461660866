// src/components/TextToSpeech.jsx
import React, { useEffect } from 'react';

const TextToSpeech = ({ text }) => {
  const speech = new SpeechSynthesisUtterance(text);

  useEffect(() => {
    // Cleanup function to stop speech when component unmounts
    return () => {
      window.speechSynthesis.cancel();
    };
  }, []);

  const speak = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.speak(speech);
    } else {
      alert("Sorry, your browser doesn't support text to speech!");
    }
  };

  const stop = () => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
    }
  };

  return (
    <div>
      <button onClick={speak} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
        Lire le texte
      </button>
      <button onClick={stop} className="mt-4 ml-2 px-4 py-2 bg-red-500 text-white rounded">
        Arrêter
      </button>
    </div>
  );
};

export default TextToSpeech;
