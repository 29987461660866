import React from 'react';

export const Card = ({ children }) => <div className="bg-white shadow-md rounded-lg p-4-tailwind">{children}</div>;

export const CardHeader = ({ children }) => <div className="border-b pb-2 mb-4">{children}</div>;

export const CardTitle = ({ children }) => <h3 className="text-lg font-bold">{children}</h3>;

export const CardContent = ({ children }) => <div className="mb-4">{children}</div>;

export const CardFooter = ({ children }) => <div className="border-t pt-2 mt-4">{children}</div>;

export const CardDescription = ({ children }) => <p className="text-sm text-gray-600">{children}</p>;
