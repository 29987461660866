import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/shared/reducers/user-management';
import { ProfessionalStatus } from 'app/shared/model/enumerations/professional-status.model';
import { getEntity, updateEntity, createEntity, reset } from './professional.reducer';
import { getEntity as getClinic } from '../clinic/clinic.reducer';
import { mapIdList } from 'app/shared/util/entity-utils';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import ProfessionalDetail from './professional-detail';

export const ProfessionalUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const { clinicId } = useParams<'clinicId'>();
  const isNew = id === undefined;
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const selectRef = useRef(null);

  const clinic = useAppSelector(state => state.clinic.entity);
  const professionalEntity = useAppSelector(state => state.professional.entity);
  const loading = useAppSelector(state => state.professional.loading);
  const updating = useAppSelector(state => state.professional.updating);
  const updateSuccess = useAppSelector(state => state.professional.updateSuccess);
  const authorities = useAppSelector(state => state.authentication.account.authorities);
  const proIsHolder = useRef(false);
  const professionalStatusValues = Object.keys(ProfessionalStatus).filter(status => status !== ProfessionalStatus.HOLDER || hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]));

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [corporateName, setCorporateName] = useState('');

  const handleClose = () => {
    const redirection = clinicId ? `/clinic/${clinicId}` : '/professional';
    navigate(redirection);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      dispatch(getClinic(clinicId));
    } else {
      dispatch(getEntity(id));
      setSelectedStatuses(professionalEntity.status);
      setFirstName(professionalEntity.firstName || '');
      setLastName(professionalEntity.lastName || '');
      setCorporateName(professionalEntity.corporateName || '');
    }

    dispatch(getUsers({}));
  }, [id, clinicId, isNew, dispatch]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess, handleClose]);

  useEffect(() => {
    if (professionalEntity?.status?.includes(ProfessionalStatus.HOLDER)) {
      proIsHolder.current = true;
    }
  }, [professionalEntity]);

  const handleStatusChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const value = event.target.value;
    const newSelection = [...selectedStatuses];
    const index = newSelection.indexOf(value);
    if (index > -1) {
      newSelection.splice(index, 1);
    } else {
      newSelection.push(value);
    }
    setSelectedStatuses(newSelection);
  };

  const handleBlur = () => {
    if (firstName && lastName && !corporateName) {
      setCorporateName(`${firstName} ${lastName}`);
    }
  };

  const saveEntity = values => {
    const entity = {
      ...professionalEntity,
      ...values,
      user: professionalEntity?.user,
      clinics: mapIdList([clinic.id]),
      status: addHolderIfNotReachable(values.status)
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const addHolderIfNotReachable = (status) => {
    if (!hasAnyAuthority(authorities, [AUTHORITIES.ADMIN]) && proIsHolder.current && !status.includes(ProfessionalStatus.HOLDER)) {
      status.push(ProfessionalStatus.HOLDER);
    }
    return status;
  }

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...professionalEntity,
          status: selectedStatuses,
          user: professionalEntity?.user?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="dfaiApp.professional.home.createOrEditLabel" data-cy="ProfessionalCreateUpdateHeading">
            <Translate contentKey="dfaiApp.professional.home.createOrEditLabel">Create or edit a Professional</Translate>
          </h2>
        </Col>
      </Row>
      {clinic && 
        <Row className="justify-content-center">
          <Col md="8">
            <h3>{clinic.name}</h3>
          </Col>
        </Row>
      }
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="professional-id"
                  label={translate('dfaiApp.professional.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('dfaiApp.professional.firstName')}
                id="professional-firstName"
                name="firstName"
                data-cy="firstName"
                type="text"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                onBlur={handleBlur}
              />
              <ValidatedField
                label={translate('dfaiApp.professional.lastName')}
                id="professional-lastName"
                name="lastName"
                data-cy="lastName"
                type="text"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                onBlur={handleBlur}
              />
              <ValidatedField
                label={translate('dfaiApp.professional.status')}
                id="professional-status"
                name="status"
                multiple
                onChange={handleStatusChange}
                data-cy="status"
                ref={selectRef}
                value={selectedStatuses}
                type="select"
              >
                {professionalStatusValues.map(professionalStatus => (
                  <option value={professionalStatus} key={professionalStatus}>
                    {translate('dfaiApp.ProfessionalStatus.' + professionalStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('dfaiApp.professional.corporateName')}
                id="professional-corporateName"
                name="corporateName"
                data-cy="corporateName"
                type="text"
                value={corporateName}
                onChange={e => setCorporateName(e.target.value)}
              />
              <ValidatedField
                label={translate('dfaiApp.professional.address')}
                id="professional-address"
                name="address"
                data-cy="address"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.zipCode')}
                id="professional-zipCode"
                name="zipCode"
                data-cy="zipCode"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.city')}
                id="professional-city"
                name="city"
                data-cy="city"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.country')}
                id="professional-country"
                name="country"
                data-cy="country"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.phoneNumber')}
                id="professional-phoneNumber"
                name="phoneNumber"
                data-cy="phoneNumber"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.email')}
                id="professional-email"
                required
                name="email"
                data-cy="email"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.website')}
                id="professional-website"
                name="website"
                data-cy="website"
                type="text"
              />
              <ValidatedField
                label={translate('dfaiApp.professional.rpps')}
                id="professional-rpps"
                name="rpps"
                data-cy="rpps"
                type="text"
                required={ selectedStatuses.filter(status => status !== ProfessionalStatus.HELPER_OR_SECRETARY).length > 0 ? true : false}
              />
              <ValidatedField
                label={translate('dfaiApp.professional.siret')}
                id="professional-siret"
                name="siret"
                data-cy="siret"
                type="text"
              />
              <Button tag={Link} to={`/clinic/${clinicId}`} replace color="info" data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProfessionalUpdate;
