import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IPatient, defaultValue } from 'app/shared/model/patient.model';

const initialState: EntityState<IPatient> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  overview: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false
};

const apiUrl = 'api/patients';
const clinicApiUrl = 'api/clinics'

// Actions

export const getEntities = createAsyncThunk('patient/fetch_entity_list', async ({ page, size, sort, search }: IQueryParams) => {
  const searchQuery = search ? `search=${search}&` : '';
  const requestUrl = `${apiUrl}?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}${searchQuery}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPatient[]>(requestUrl);
});
export const getClinicPatients = createAsyncThunk('clinic/patient/fetch_entity_list', async ({ id, page, size, sort }: IQueryParams) => {
  const requestUrl = `${clinicApiUrl}/${id}/patients?${sort ? `page=${page}&size=${size}&sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<IPatient[]>(requestUrl);
});


export const getEntity = createAsyncThunk(
  'patient/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IPatient>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getPatientPhoto = createAsyncThunk<Blob, string>(
  'patient/fetch_photo',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}/photo`;
    const response = await axios.get(requestUrl, { responseType: 'blob' });
    return response.data;
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'patient/create_entity',
  async (entity: FormData, thunkAPI) => {
    const result = await axios.post<IPatient>(apiUrl, entity, {headers: {"Content-Type":"multipart/form-data"}});
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'patient/update_entity',
  async (entity: FormData, thunkAPI) => {
    const id = entity.get("id");
    entity.delete("id");
    const result = await axios.post<IPatient>(`${apiUrl}/${id}`, entity, {headers: {"Content-Type":"multipart/form-data"}});
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'patient/partial_update_entity',
  async (entity: IPatient, thunkAPI) => {
    const result = await axios.patch<IPatient>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

/**
 * Just the same as partialUpdateEntity, but without the thunkAPI.dispatch(getEntities({})) call
 */
export const blindPartialUpdateEntity = createAsyncThunk(
  'patient/partial_update_entity',
  async (entity: IPatient, thunkAPI) => {
    const result = await axios.patch<IPatient>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'patient/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IPatient>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const PatientSlice = createEntitySlice({
  name: 'patient',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getClinicPatients), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = PatientSlice.actions;

// Reducer
export default PatientSlice.reducer;
