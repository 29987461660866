import React from 'react';

const TermsOfService = () => {
  return (
    <div className="container mx-auto p-4">
      <main className="flex-1 container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Conditions d'Utilisation</h1>
        <p className="mb-4">
          Bienvenue sur Dragonfly AI Medical. En utilisant notre site, vous acceptez les présentes conditions d'utilisation.
        </p>
        <h2 className="text-2xl font-bold mb-2">Utilisation du site</h2>
        <p className="mb-4">
          Vous vous engagez à utiliser notre site de manière légale et conforme aux présentes conditions. Vous ne devez pas utiliser notre site d'une manière qui pourrait endommager, désactiver, surcharger ou altérer nos services ou interférer avec l'utilisation et la jouissance du site par toute autre partie.
        </p>
        <h2 className="text-2xl font-bold mb-2">Propriété Intellectuelle</h2>
        <p className="mb-4">
          Tout le contenu de ce site, y compris les textes, graphiques, logos, icônes, images, clips audio, téléchargements numériques et compilations de données, est la propriété de Dragonfly AI Medical ou de ses fournisseurs de contenu et est protégé par les lois françaises et internationales sur le droit d'auteur.
        </p>
        <h2 className="text-2xl font-bold mb-2">Modifications des Conditions</h2>
        <p className="mb-4">
          Nous nous réservons le droit de modifier ces conditions d'utilisation à tout moment. Vous devriez consulter cette page régulièrement pour prendre connaissance des changements. Votre utilisation continue de notre site après la publication des modifications constitue une acceptation des nouvelles conditions.
        </p>
        <h2 className="text-2xl font-bold mb-2">Contactez-nous</h2>
        <p className="mb-4">
          Si vous avez des questions concernant ces conditions d'utilisation, vous pouvez nous contacter à : contact@dragonflyai-medical.com.
        </p>
      </main>
    </div>
  );
};

export default TermsOfService;
