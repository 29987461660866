import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from '../components/ui/Card';
import { CalendarIcon, LocateIcon, UsersIcon, FileTextIcon, FileIcon } from '../components/icons';
import ConsultationList from '../components/ConsultationList';
import { useAppDispatch, useAppSelector } from 'app/config/store';

const Appointments = () => {
  const patientId = useAppSelector(state => state.authentication.account.loggedPatientInfo?.id);
  const [appointments, setAppointments] = useState([]);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);

  if (!isAuthenticated) {
    return <div>Veuillez vous créer votre compte</div>;
  }

  return (
    <div className="container mx-auto ">
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 bg-muted flex flex-col items-center">
          <div className="container space-y-12 mx-auto">
            <div className="grid gap-4">
              <ConsultationList />
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Appointments;
