import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-4">
      <main className="flex-1 container mx-auto p-4">
        <h1 className="text-3xl font-bold mb-4">Politique de Confidentialité</h1>
        <p className="mb-4">
          Chez Dragonfly AI Medical, nous respectons votre vie privée et nous nous engageons à protéger les données personnelles que nous collectons.
        </p>
        <h2 className="text-2xl font-bold mb-2">Collecte des données</h2>
        <p className="mb-4">
          Nous collectons diverses données personnelles lorsque vous utilisez notre site, notamment votre nom, adresse e-mail, numéro de téléphone, etc.
        </p>
        <h2 className="text-2xl font-bold mb-2">Utilisation des données</h2>
        <p className="mb-4">
          Les données collectées sont utilisées pour améliorer nos services, personnaliser votre expérience sur notre site, et communiquer avec vous.
        </p>
        <h2 className="text-2xl font-bold mb-2">Partage des données</h2>
        <p className="mb-4">
          Nous ne partageons pas vos données personnelles avec des tiers sans votre consentement, sauf si cela est nécessaire pour fournir nos services ou si la loi l'exige.
        </p>
        <h2 className="text-2xl font-bold mb-2">Sécurité des données</h2>
        <p className="mb-4">
          Nous mettons en œuvre diverses mesures de sécurité pour protéger vos données personnelles contre les accès non autorisés, les modifications, la divulgation ou la destruction.
        </p>
        <h2 className="text-2xl font-bold mb-2">Vos droits</h2>
        <p className="mb-4">
          Vous avez le droit d'accéder à vos données personnelles, de les corriger, de demander leur suppression ou de vous opposer à leur traitement. Pour exercer ces droits, veuillez nous contacter à : contact@dragonflyai-medical.com.
        </p>
      </main>
    </div>
  );
};

export default PrivacyPolicy;
