import React, { useState, useEffect, useRef, createRef } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity as getConsultation, getEntityReport } from 'app/entities/patient/consultation/consultation.reducer';
import { getConsultationEntities as getQuotes } from 'app/entities/patient/consultation/quotes/quote.reducer';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/Card';
import PatientModalWithLegend from '../components/PatientModalWithLegend';
import ImageCollectionRenderer from '../components/ImageCollectionRenderer';
import TextToSpeech from '../components/TextToSpeech';
import { FileIcon, FileTextIcon } from '../components/icons';
import { translate } from 'react-jhipster';

const AppointmentDetail = () => {
  const patientId = useAppSelector(state => state.authentication.account.loggedPatientInfo?.id);
  const consultationId = useParams().id;
  const [detailedImage, setDetailedImage] = useState(null);
  const [panoramics, setPanoramics] = useState([]);
  const [xRays, setXRays] = useState([]);
  const [photos, setPhotos] = useState([]);
  const quotes = useAppSelector(state => state.quote.entities);
  const consultation = useAppSelector(state => state.consultation.entity);
  const dispatch = useAppDispatch();
  const imageContainers = useRef(new Map());
  const [hideLegendsInModal, setHideLegendsInModal] = useState(false);
  const [mailText, setMailText] = useState(null);

  // Fetch specific consultation data and report
  useEffect(() => {
    if (patientId && consultationId) {
      dispatch(getConsultation({ patientId, consultationId }));
      dispatch(getEntityReport({ patientId, consultationId, locale: 'FR' }));
    }
  }, [patientId, consultationId, dispatch]);

  const recommendations = consultation?.report?.recommendations;
  const recommendationsText = recommendations
  ? `${translate('dfaiApp.consultation.report.mailPrefix')}\n${recommendations
      .map(rec => rec.replaceAll('\\n', '\n'))
      .join('\n\n')}\n${translate('dfaiApp.consultation.report.mailSuffix')}`
  : '';


  useEffect(() => {
    if (consultation?.report?.mail) {
      setMailText(consultation.report.mail);
    }
  }, [consultation]);

  useEffect(() => {
    if (consultation) {
      const images = consultation.images || [];
      const newXRays = [];
      const newPhotos = [];
      const newPanoramics = [];

      images
        .filter(image => image.consultation.id === consultationId)
        .forEach(image => {
          if (image.type === 'panoramic') {
            newPanoramics.push(image);
            imageContainers.current.set(image.id, createRef());
          } else if (image.type === 'xray') {
            newXRays.push(image);
            imageContainers.current.set(image.id, createRef());
          } else if (image.type === 'photo') {
            newPhotos.push(image);
            imageContainers.current.set(image.id, createRef());
          }
        });

      setXRays(newXRays);
      setPhotos(newPhotos);
      setPanoramics(newPanoramics);

      if (patientId && consultationId) {
        dispatch(getQuotes({ patientId, consultationId }));
      }
    }
  }, [consultation, consultationId, patientId, dispatch]);

  const handleViewQuote = quote => {
    if (patientId && consultationId) {
      window.open(`api/patients/${patientId}/consultations/${consultationId}/quotes/${quote.id}/file`, '_blank');
    } else {
      console.error("Patient ID or Consultation ID is missing");
    }
  };



  return (
    <div className="container mx-auto ">
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 bg-muted flex flex-col items-center">
          <div className="container space-y-12 md:px-6 mx-auto">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-5xl">Détails du Rendez-vous</h2>
            {/* Images Section */}
            <div className="flex flex-col space-y-4">
              {(panoramics.length || xRays.length || photos.length) ? (
                <Card>
                  <CardHeader>
                    <CardTitle>Prédictions</CardTitle>
                  </CardHeader>
                  <CardContent>
                    {panoramics.length > 0 && (
                      <ImageCollectionRenderer
                        title="Panoramique"
                        images={panoramics}
                        height="35vh"
                        minHeight="100px"
                        singleLine={true}
                        setDetailedImage={setDetailedImage}
                        setHideLegendsInModal={setHideLegendsInModal}
                        imageContainers={imageContainers}
                        patientId={patientId}
                      />
                    )}
                    {xRays.length > 0 && (
                      <ImageCollectionRenderer
                        title="Radios"
                        images={xRays}
                        height="15vh"
                        minHeight="15vh"
                        setDetailedImage={setDetailedImage}
                        setHideLegendsInModal={setHideLegendsInModal}
                        imageContainers={imageContainers}
                        patientId={patientId}
                        withPredictionOnly={true}
                      />
                    )}
                    {photos.length > 0 && (
                      <ImageCollectionRenderer
                        title="Photos"
                        images={photos}
                        height="15vh"
                        minHeight="15vh"
                        setDetailedImage={setDetailedImage}
                        setHideLegendsInModal={setHideLegendsInModal}
                        imageContainers={imageContainers}
                        patientId={patientId}
                        withPredictionOnly={true}
                      />
                    )}
                  </CardContent>
                </Card>
              ) : null}
            </div>

            <div className="grid gap-8 md:grid-cols-2">
              <Card>
                <CardHeader>
                  <CardTitle>Rapports</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-4">
                    {consultation?.report && (
                      <div className="flex items-center gap-2">
                        <FileTextIcon className="h-8 w-8 text-muted-foreground" />
                        <div className="grid gap-1">
                          <div className="font-medium">Rapport de consultation</div>
                        </div>
                        <Link to={`/patient-app/report/${consultation.report.consultationId}`} className="ml-auto">
                          <FileIcon className="h-8 w-8 text-muted-foreground" />
                        </Link>
                      </div>
                    )}
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Devis</CardTitle>
                </CardHeader>
                <CardContent>
                  <div className="grid gap-4">
                    {Array.isArray(quotes) &&
                      quotes.slice(0, 4).map((quote, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <FileTextIcon className="h-8 w-8 text-muted-foreground" />
                          <div className="grid gap-1">
                            <div className="font-medium"> {quote.originalName || quote.sourceFileName}</div>
                          </div>
                          <FileIcon
                            onClick={() => handleViewQuote(quote)}
                            className="cursor-pointer h-8 w-8 text-muted-foreground ml-auto"
                          />
                        </div>
                      ))}
                  </div>
                </CardContent>
              </Card>
            </div>

            {/* Practitioner Letter */}
            <Card>
              <CardHeader>
                <CardTitle>Courrier de votre praticien</CardTitle>
              </CardHeader>
              <CardContent>
                {mailText ? (
                  <>
                    <p className="text-muted-foreground">{mailText}</p>
                    <TextToSpeech text={mailText} />
                  </>
                ) : recommendationsText ? (
                  <>
                    <p className="text-muted-foreground">{recommendationsText}</p>
                    <TextToSpeech text={recommendationsText} />
                  </>
                ) : (
                  <p className="text-muted-foreground">Aucun courrier disponible.</p>
                )}
              </CardContent>
            </Card>

            {/* More Images Section */}
            {(panoramics.length || xRays.length || photos.length) && (
              <Card>
                <CardHeader>
                  <CardTitle>Photos</CardTitle>
                </CardHeader>
                <CardContent>
                  {panoramics.length > 0 && (
                    <ImageCollectionRenderer
                      title="Panoramique"
                      images={panoramics}
                      hidePrediction={true}
                      height="35vh"
                      minHeight="100px"
                      singleLine={true}
                      setDetailedImage={setDetailedImage}
                      setHideLegendsInModal={setHideLegendsInModal}
                      imageContainers={imageContainers}
                      patientId={patientId}
                    />
                  )}
                  {xRays.length > 0 && (
                    <ImageCollectionRenderer
                      title="Radios"
                      images={xRays}
                      hidePrediction={true}
                      height="15vh"
                      minHeight="15vh"
                      setDetailedImage={setDetailedImage}
                      setHideLegendsInModal={setHideLegendsInModal}
                      imageContainers={imageContainers}
                      patientId={patientId}
                    />
                  )}
                  {photos.length > 0 && (
                    <ImageCollectionRenderer
                      title="Photos"
                      images={photos}
                      hidePrediction={true}
                      height="15vh"
                      minHeight="15vh"
                      setDetailedImage={setDetailedImage}
                      setHideLegendsInModal={setHideLegendsInModal}
                      imageContainers={imageContainers}
                      patientId={patientId}
                    />
                  )}
                </CardContent>
              </Card>
            )}
          </div>
          {detailedImage && (
            <PatientModalWithLegend
              selectedImageEntity={detailedImage}
              closePredictionModal={() => setDetailedImage(null)}
              patientId={patientId}
              simpleImages={hideLegendsInModal}
              legends={!hideLegendsInModal}
            />
          )}
        </section>
      </main>
    </div>
  );
};

export default AppointmentDetail;
