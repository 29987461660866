import ImageViewer from 'app/entities/patient/consultation/image/image-viewer';

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getConsultationImage } from 'app/entities/patient/consultation/consultation.reducer';
import { IImage } from 'app/shared/model/image.model';

interface SimpleImageViewerProps {
  imageEntity: IImage;
  className?: string;
  //withPredictionOnly?: boolean;
  //hidePrediction?: boolean;
  //setDetailedImage: (image: IImage) => void;
  //imageContainers: React.RefObject<Map<string, HTMLDivElement>>;
  patientId: string;
  testBlack?: boolean;
}

/**
 * A very simple image viewer that displays an image fo a photo, radio, panoramic.
 */
const SimpleImageViewer = ({ imageEntity, withPredictionOnly = false, patientId, testBlack = false, className }) => {
  //const {imageEntity} = props

  // Filtrer les images en fonction de withPredictionOnly

  const dispatch = useAppDispatch();
  const [imageUrl, setImageUrl] = useState('');
  useEffect(() => {
    const fetchImage = async () => {
      if (imageEntity?.storedName) {
        try {
          setImageUrl(null);
          const response = await dispatch(getConsultationImage({ patientId, image: imageEntity })).unwrap();
          const objectURL = URL.createObjectURL(response);
          setImageUrl(objectURL);
          return () => URL.revokeObjectURL(objectURL);
        } catch (error) {
          console.error('Failed to load image:', error);
        }
      }
    };
    fetchImage();
  }, [dispatch, imageEntity, patientId]);

  return <img className={className} src={imageUrl} />;
};

export default SimpleImageViewer;
